import Alpine from 'alpinejs';
import Header from './frontend/header';
import Search from './frontend/search';
import Video from './frontend/video';
import CoverageTableLink from './frontend/coveragetable_link';
import ComparisonTable from './frontend/comparisontable';
import Global from './frontend/global';
import Forms from './frontend/forms';
import Cards from './frontend/cards';
import Links from './frontend/links';
import Intercom from './frontend/intercom';
import Timeline from './frontend/timeline';
import Glossary from './frontend/glossary';

// App
Header.init();
Search.init();
Video.init();
CoverageTableLink.init();
ComparisonTable.init();
Global.init();
Forms.init();
Cards.init();
Links.init();
Intercom.init();
Timeline.init();
Glossary.init();

// Alpine
window.Alpine = Alpine;
Alpine.start();
